export const browseData = [
  {
    url: "/pages/text-to-speech",
    text: "text to speech",
  },
  {
    url: "/pages/voice-clone",
    text: "voice clone",
  },
];
